<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Bildirim Yükle</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Excel Yükle"
            @click="_BildirimYukleModal()" />
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_BildirimModal()" />
        </div>
        <DataTable_Custom v-if="DataTable_render" :columns="columns" :data="BildirimYukleListesi" :detailbutton="true"
          v-on:set-child-data="getDataChild" v-on:selected_rows="getSelectedRows" datakey="Bildirim_rowid" />
      </template>
    </Card>

    <Dialog v-model:visible="BildirimModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Bildirim</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanMukellef">Mükellef</span>
          <Dropdown id="drpMukellef" v-model="pMukellef" :options="MukellefListesi" optionLabel="Ad"
            optionValue="Mukellef_rowid" placeholder="Seçiniz..." />
        </div>
        <div class="p-field p-col-6">
          <span for="spanOdemeGrup">Ödeme Grup</span>
          <Dropdown id="drpOdemeGrup" v-model="pOdemeGrup" :options="OdemeGrupListesi" optionLabel="Ad"
            optionValue="OdemeGrup_rowid" placeholder="Seçiniz..." />
        </div>
        <div class="p-field p-col-6">
          <span for="spanBildirimTarih">Bildirim Tarih </span>
          <Calendar :showIcon="true" v-model="v$.BildirimTarih.$model" aria-labelledby="spanBildirimTarih"
            :class="{ 'p-invalid': v$.BildirimTarih.$invalid && submitted }" />
          <small v-if="(v$.BildirimTarih.$invalid && submitted) ||
              v$.BildirimTarih.$pending.$response
              " class="p-error">{{
              v$.BildirimTarih.required.$message.replace("Value", "Name")
            }}</small>
        </div>
        <div class="p-field p-col-6">
          <span for="spanTutar">Tutar</span>
          <InputText type="text" v-model="v$.Tutar.$model" aria-labelledby="spanTutar"
            :class="{ 'p-invalid': v$.Tutar.$invalid && submitted }" />
          <small v-if="(v$.Tutar.$invalid && submitted) ||
              v$.Tutar.$pending.$response
              " class="p-error">{{
              v$.Tutar.required.$message.replace("Value", "Name")
            }}</small>
        </div>


      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._BildirimModal()" />
      </template>
    </Dialog>

    <!--  -->

    <Dialog v-model:visible="BildirimYukleModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Bildirim Excel Yükle</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12">
          <span for="spanOdemeGrup">Ödeme Grup</span>
          <Dropdown id="drpOdemeGrup" v-model="pOdemeGrup" :options="OdemeGrupListesi" optionLabel="Ad"
            optionValue="OdemeGrup_rowid" placeholder="Seçiniz..." />
        </div>

        <!-- @change="ExcelUpload" -->
        <div class="p-field p-col-12">
          <FileUpload name="demo[]" :customUpload="true" chooseLabel="Seçin" uploadLabel="Yükle" cancelLabel="İptal"
            :multiple="false" accept=".xlsx" :maxFileSize="1000000" buttonClass="p-button-sm" @uploader="ExcelUpload">
            <template #empty>
              <p>Dosyanızı buraya sürükleyebilirsiniz.</p>
            </template>
          </FileUpload>

          <!-- <input class="p-button p-component p-button-sm p-mr-2 p-mb-2 " type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="ExcelUpload"> -->
        </div>
      </div>
      <template #footer>
        <!-- <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus /> -->
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger"
          @click="this.BildirimYukleModal = false" />
      </template>
    </Dialog>

    <!--  -->

    <Dialog v-model:visible="BildirimSonucModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Bildirim Excel Yükle Sonuçları</h4>
      </template>

      <DataTable v-if="Sonuc_DataTable_render" :lazy="true" :value="tamamlanan_exceldata" showGridlines stripedRows
        class="p-datatable-sm" responsiveLayout="scroll">

        <Column sortable header="Durum" field="Durum">
          <template #body="{ data }">
            <Tag :value="data.Durum" :severity="getSeverity(data.Durum)" />
          </template>
        </Column>
        <Column field="Unvan" header="Unvan"></Column>
        <Column field="AdSoyad" header="AdSoyad"></Column>
        <Column field="Tutar" header="Tutar"></Column>
        <Column field="VergiNo" header="VKN/TCKN"></Column>
        <Column field="Neden" header="Neden"></Column>
      </DataTable>

      <template #footer>
        <!-- <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!v$.$invalid)" autofocus /> -->
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger"
          @click="this.BildirimSonucModal = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable_Custom from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Exceljs from 'exceljs';

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      Sonuc_DataTable_render: false,
      BildirimPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Kullanici, False = Edit Mukellef
      BildirimYukleListesi: [],
      MukellefListesi: [],
      OdemeGrupListesi: [],
      BildirimModal: false,
      BildirimYukleModal: false,
      BildirimSonucModal: false,
      columns: [
        { field: "Unvan", header: "Ünvan" },
        { field: "Ad", header: "Ödeme Grubu" },
        { field: "Donem", header: "Dönem" },
        { field: "Tutar", header: "Tutar" },
        { field: "BildirimTarih", header: "Bildirim Tarih" },
      ],
      Bildirim_rowid: null,
      Unvan: null,
      Ad: null,
      Donem: null,
      Tutar: null,
      BildirimTarih: null,

      pMukellef: null,
      pOdemeGrup: null,

      tamamlanan_exceldata: [],  //  Ad Soyad, Tutar, ünvan, vergino
      temp_exceldata: [],

      hatavarmi: false
    };
  },
  methods: {
    ExcelUpload(event) {
      this.tamamlanan_exceldata = [];
      if (this.pOdemeGrup == null || this.pOdemeGrup == "") {
        this.$toast.add({
          severity: "warn",
          summary: "Uyarı",
          detail: "Ödeme Grubu Seçin",
          life: 3000,
        });
        return;
      }
      const f = event.files[0];
      const workbook = new Exceljs.Workbook();
      var fileReader = new FileReader();
      fileReader.onload = (e) => {
        const buffer = e.target.result;
        workbook.xlsx.load(buffer).then((wb) => {
          const worksheet = wb.getWorksheet(1);
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber !== 1) {
              let data = {
                AdSoyad: row.model.cells[0].value,
                Tutar: row.model.cells[1].value,
                Unvan: row.model.cells[2].value,
                VergiNo: row.model.cells[3].value,
                pOdemeGrup: this.pOdemeGrup
              };
              setInterval(() => { }, 500);
              GlobalServis.GlobalServis("POST", "SetBildirimYukle", data).then(
                (res) => {
                  if (res.status == 200) {
                    var sonucarray = res.data.split('-');
                    if (sonucarray[0] == "Başarılı") {
                      this.tamamlanan_exceldata.push({
                        Durum: "Başarılı",
                        AdSoyad: row.model.cells[0].value,
                        Tutar: row.model.cells[1].value,
                        Unvan: row.model.cells[2].value,
                        VergiNo: row.model.cells[3].value,
                        Neden: ""
                      });
                    }
                    else {
                      this.tamamlanan_exceldata.push({
                        Durum: "Başarısız",
                        AdSoyad: row.model.cells[0].value,
                        Tutar: row.model.cells[1].value,
                        Unvan: row.model.cells[2].value,
                        VergiNo: row.model.cells[3].value,
                        Neden: sonucarray[1]
                      });
                    }

                  }
                  else
                    this.tamamlanan_exceldata.push({
                      Durum: "Başarısız",
                      AdSoyad: row.model.cells[0].value,
                      Tutar: row.model.cells[1].value,
                      Unvan: row.model.cells[2].value,
                      VergiNo: row.model.cells[3].value,
                      Neden: "Bilinmeyen hata"
                    });
                }
              )
            }
            //  row.eachCell((cell, colNumber) => {  console.log(`Cell value at row ${rowNumber}, column ${colNumber}: ${cell.value}`); });
          });
          console.log("readFile success");
        }).catch((error) => {
          console.log("readFile fail", error);
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "İşleme sırasında hata ile karşılaşıldı.",
            life: 3000,
          });
        }).finally(() => {
          this.BildirimSonucModal = true;
          this.Sonuc_DataTable_render = true;
          this.BildirimYukleModal = false;
          this.Refresh();
        })
      };
      fileReader.readAsArrayBuffer(f);
    },
    getDataChild(getdata_child) {
      this.resetForm();
      this.BildirimPopUpDurumu = false;
      this.BildirimModal = !this.BildirimModal;
      this.Bildirim_rowid = getdata_child.data.Bildirim_rowid;
      let data = {
        Bildirim_rowid: getdata_child.data.Bildirim_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetBildirimYukleInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.pMukellef = res.data[0].pMukellef;
            this.pOdemeGrup = res.data[0].pOdemeGrup;
            this.v$.BildirimTarih.$model = res.data[0].BildirimTarih;
            this.v$.Tutar.$model = res.data[0].Tutar;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Bildirim_rowid: this.Bildirim_rowid,
          pMukellef: this.pMukellef,
          pOdemeGrup: this.pOdemeGrup,
          BildirimTarih: this.BildirimTarih,
          Tutar: this.Tutar
        };
        if (this.BildirimPopUpDurumu) {
          // Yeni Mukellef
          GlobalServis.GlobalServis("POST", "SetManuelBildirimYukle", data).then(
            (res) => {
              if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Bildirim oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._BildirimModal();
              }else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          // Edit Mukellef
          GlobalServis.GlobalServis("POST", "UpdateBildirimYukle", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Mukellef düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this.BildirimModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    _BildirimModal() {
      this.BildirimPopUpDurumu = true;
      this.BildirimModal = !this.BildirimModal;
      if (this.BildirimModal) {
        this.resetForm();
      }
    },
    _BildirimYukleModal() {
      this.BildirimYukleModal = !this.BildirimYukleModal;
      if (this.BildirimYukleModal) {
        this.resetForm();
      }
    },
    _BildirimSonucModal() {
      this.BildirimSonucModal = !this.BildirimSonucModal;
      if (this.BildirimSonucModal) {
        this.resetForm();
      }
    },
    resetForm() {
      // this.v$.$reset;
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      this._GetBildirimYukleTablosu();
      this._GetOdemeGrup();
      this._GetMukellef();
    },
    _GetBildirimYukleTablosu() {
      GlobalServis.GlobalServis("GET", "GetBildirimYukleTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.BildirimYukleListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
    _GetMukellef() {
      GlobalServis.GlobalServis("GET", "GetMukellef", "").then(
        (res) => {
          if (res.status == 200) {
            this.MukellefListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
    _GetOdemeGrup() {
      GlobalServis.GlobalServis("GET", "GetOdemeGrup", "").then(
        (res) => {
          if (res.status == 200) {
            this.OdemeGrupListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
    getSeverity(status) {
      switch (status) {
        case "Başarılı":
          return "success";
        case "Başarısız":
          return "danger";
        case null:
          return null;
      }
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable_Custom,
  },
  validations() {
    return {
      BildirimTarih: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      Tutar: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
